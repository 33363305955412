import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import BecomeMember from "../Pages/BecomeMember";
import ContactUs from "../Pages/ContactUs";
import Gallery from "../Pages/Gallery";
import GrowYourBusiness from "../Pages/GrowYourBusiness";
import Home from "../Pages/Home";
import HomeWrapper from "../Components/HomeWrapper";
import MemberBenefit from "../Pages/MemberBenefit";
import Members from "../Pages/Members";
import PresidentMessage from "../Pages/PresidentMessage";
import ProjectDetails from "../Pages/ProjectDetails";
import WeCommitted from "../Pages/WeCommitted";
import WhyGBA from "../Pages/WhyGBA";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PropertyFest from "../Pages/PropertyFest";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PropertyFest />} />
                {/* <Route path="/" element={<Navigate to="/home" />} /> */}
                <Route path="home" element={<HomeWrapper />}>
                    <Route index element={<Home />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="members" element={<Members />} />
                    <Route path="project-details" element={<ProjectDetails />} />
                    <Route path="become-member" element={<BecomeMember />} />
                    <Route path="grow-your-business" element={<GrowYourBusiness />} />
                    <Route path="member-benefit" element={<MemberBenefit />} />
                    <Route path="presidents-message" element={<PresidentMessage />} />
                    <Route path="we-committed" element={<WeCommitted />} />
                    <Route path="why-gba" element={<WhyGBA />} />
                    <Route path="*" element={(
                        <Grid container height="100vh" alignItems={"center"}>
                            <Grid container justifyContent={"center"}>
                                <Typography variant="h3">Page not found...</Typography>
                            </Grid>
                        </Grid>
                    )} />
                </Route>
                <Route path="*" element={<PropertyFest />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;