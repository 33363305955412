import { Box, Grid, Typography, Snackbar, Alert } from "@mui/material";
import HeaderImg from '../Assets/Images/NavratriOffer24/header.png'
import TitleImg from '../Assets/Images/NavratriOffer24/title.png'
import ProjectDetailsImg from '../Assets/Images/NavratriOffer24/project-details-2024.png'
import BgMap from '../Assets/Images/NavratriOffer24/bg-map.png'
import PrizesImg from '../Assets/Images/NavratriOffer24/prizes.png'
import CompanyGridImg from '../Assets/Images/NavratriOffer24/companies-2.png'
import CredaiLogo from '../Assets/Images/NavratriOffer24/logo-white.png'
import RegisterNowImg from '../Assets/Images/NavratriOffer24/register-now.png'
import FacebookImg from '../Assets/Images/NavratriOffer24/ic-facebook.png'
import LinkedInImg from '../Assets/Images/NavratriOffer24/ic-linkedin.png'
import InstagramImg from '../Assets/Images/NavratriOffer24/ic-instagram.png'
import RegistrationQRCode from '../Assets/Images/NavratriOffer24/registration-qr-code.png'
import LabelQRCode from '../Assets/Images/NavratriOffer24/label-qr-code.png'
import TricityLogo from '../Assets/Images/NavratriOffer24/2024-tri-city-logo.png'
import SkylineImg from '../Assets/Images/NavratriOffer24/1skyline.png'
import { useState } from "react";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';


const PropertyFest = () => {

    const [openToast, setOpenToast] = useState(false);
    const smallDeviceMatched = useMediaQuery('(max-width:1000px)');

    return (
        <Box>
            <img width="100%" src={HeaderImg} alt="" />
            <Box className="garden-wrapper">
                <Grid container justifyContent="center">
                    <Grid item className="info-container" container alignItems="center" flexDirection={"column"}>
                        <Grid item display={"flex"}>
                            <img src={TitleImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item display={"flex"}>
                            <img src={CompanyGridImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item marginTop={5} display={"flex"}>
                            <img src={ProjectDetailsImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item display={"flex"} marginTop={4}>
                            <a href="https://forms.office.com/r/41a5K7hnt7" target="_blank" >
                                <img src={RegisterNowImg} alt="" width={"100%"} />
                            </a>
                        </Grid>
                        <Grid item marginTop={1} display={"flex"}>
                            <img src={PrizesImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item marginTop={4} display={"flex"}>
                            <img src={TricityLogo} alt="" width={"100%"} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item marginTop={-8} position={"relative"} marginBottom={"-2px"} zIndex={999} display={"flex"}>
                    <img src={SkylineImg} alt="" width={"100%"} />
                </Grid>
                <Grid className="credai-details" container flexDirection="row" flexWrap={"wrap"}>
                    <Grid container xs={smallDeviceMatched ? 12 : 8}>
                        <Grid item container justifyContent={"space-between"} marginTop={smallDeviceMatched ? 10 : 0}>
                            <Grid item>
                                <Grid item>
                                    <img src={CredaiLogo} alt="" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" color="#fff">CONFEDERATION OF REAL ESTATE DEVELOPERS ASSOCIATION OF INDIA - GANDHINAGAR</Typography>
                                </Grid>
                                <Grid item container flexDirection="row" alignItems="center" justifyContent={"space-between"} flexWrap={'nowrap'}>
                                    <Grid item container gap={1} alignItems="center">
                                        <Typography variant="subtitle1" color="#fff">
                                            Visit here:
                                        </Typography>
                                        <Link to="/home">
                                            <Typography variant="caption" color="#fff">
                                                https://credaigandhinagar.com/home
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container flexDirection="row" alignItems="center">
                            <Grid item xs={6}>
                                <hr />
                            </Grid>
                            <Grid item>
                                <a href="https://www.facebook.com/profile.php?id=100089493277108" target="_blank">
                                    <img src={FacebookImg} className="ic-social-media" />
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/credaigandhinagar/" target="_blank">
                                    <img src={InstagramImg} className="ic-social-media" />
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.linkedin.com/company/90858865/admin/" target="_blank">
                                    <img src={LinkedInImg} className="ic-social-media" />
                                </a>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="#fff">407, Shreerang Heights, Opp. Bhaijipura Patiya,</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="#fff">Gandhinagar - Koba Highway, Kudasan, Gandhinagar - 382421 - Gujarat.</Typography>
                        </Grid>
                    </Grid>
                    <Grid className="qr-code-wrapper" xs={smallDeviceMatched ? 12 : 4} item alignItems={"flex-end"} container flexDirection="column">
                        <img src={RegistrationQRCode} alt="" height={"120px"} width={"120px"} />
                        <img src={LabelQRCode} alt="" />
                    </Grid>
                </Grid>
            </Box>



            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openToast}
                autoHideDuration={6000}
                onClose={() => setOpenToast(false)}
                message="Registration will be available soon!!"
            >
                <Alert onClose={() => setOpenToast(false)} severity="error" sx={{ width: '100%' }}>
                    Registration will be available soon!!
                </Alert>
            </Snackbar>
        </Box >

    );
}

export default PropertyFest;